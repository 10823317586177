
export const price1 = [
    {
      "index": 1,
      "value": "MARGHARETA",
      "category": "KLASS 1",
      "price": "110",
      "familyPrice": "270",
      "ingredients": [
        {
          "id": "ost"
        }
      ]
    },
    {
      "index": 2,
      "value": "VESUVIO",
      "category": "KLASS 1",
      "price": "110",
      "familyPrice": "270",
      "ingredients": [
        {
          "id": "skinka"
        }
      ]
    },
    {
      "index": 3,
      "value": "AL FUNGHI",
      "category": "KLASS 1",
      "price": "110",
      "familyPrice": "270",
      "ingredients": [
        {
          "id": "färska champinjoner"
        }
      ]
    },
    {
      "index": 4,
      "value": "SALAMI",
      "category": "KLASS 1",
      "price": "110",
      "familyPrice": "270",
      "ingredients": [
        {
          "id": "salami"
        }
      ]
    }
]

export const price2 = [
  {
    "index": 5,
    "value": "CAPRICCIOSA",
    "category": "KLASS 2",
    "price": "120",
    "familyPrice": "290",
    "ingredients": [
      {
        "id": "skinka"
      },
      {
        "id": "champinjoner"
      }
    ]
  },
  {
    "index": 6,
    "value": "HAWAII",
    "category": "KLASS 2",
    "price": "120",
    "familyPrice": "290",
    "ingredients": [
      {
        "id": "skinka"
      },
      {
        "id": "ananas"
      }
    ]
  },
  {
    "index": 7,
    "value": "BUSSOLA",
    "category": "KLASS 2",
    "price": "120",
    "familyPrice": "290",
    "ingredients": [
      {
        "id": "skinka"
      },
      {
        "id": "räkor"
      }
    ]
  },
  {
    "index": 8,
    "value": "ROMA",
    "category": "KLASS 2",
    "price": "120",
    "familyPrice": "290",
    "ingredients": [
      {
        "id": "tonfisk"
      },
      {
        "id": "räkor"
      }
    ]
  },
  {
    "index": 9,
    "value": "AL TONO",
    "category": "KLASS 2",
    "price": "120",
    "familyPrice": "290",
    "ingredients": [
      {
        "id": "tonfisk"
      },
      {
        "id": "lök"
      }
    ]
  }
]

export const price3 = [
  {
    "index": 10,
    "value": "GENOA",
    "category": "KLASS 3",
    "price": "125",
    "familyPrice": "310",
    "ingredients": [
      {
        "id": "ananas"
      },
      {
        "id": "banan"
      },
      {
        "id": "jordnötter"
      },
      {
        "id": "curry"
      }
    ]
  },
  {
    "index": 11,
    "value": "SOLE MIO",
    "category": "KLASS 3",
    "price": "125",
    "familyPrice": "310",
    "ingredients": [
      {
        "id": "skinka"
      },
      {
        "id": "ananas"
      },
      {
        "id": "banan"
      },
      {
        "id": "curry"
      }
    ]
  },
  {
    "index": 12,
    "value": "ROMANA",
    "category": "KLASS 3",
    "price": "125",
    "familyPrice": "310",
    "ingredients": [
      {
        "id": "skinka"
      },
      {
        "id": "champinjoner"
      },
      {
        "id": "räkor"
      }
    ]
  },
  {
    "index": 13,
    "value": "GORGONZOLA",
    "category": "KLASS 3",
    "price": "125",
    "familyPrice": "310",
    "ingredients": [
      {
        "id": "skinka"
      },
      {
        "id": "sparris"
      },
      {
        "id": "gorgonzolaost"
      }
    ]
  },
  {
    "index": 14,
    "value": "MAMMA MIA",
    "category": "KLASS 3",
    "price": "125",
    "familyPrice": "310",
    "ingredients": [
      {
        "id": "skinka"
      },
      {
        "id": "champinjoner"
      },
      {
        "id": "bacon"
      },
      {
        "id": "räkor"
      }
    ]
  },
  {
    "index": 15,
    "value": "JOHN BLUND",
    "category": "KLASS 3",
    "price": "125",
    "familyPrice": "310",
    "ingredients": [
      {
        "id": "skinka"
      },
      {
        "id": "champinjoner"
      },
      {
        "id": "bacon"
      },
      {
        "id": "ägg"
      }
    ]
  },
  {
    "index": 16,
    "value": "CARBONARA",
    "category": "KLASS 3",
    "price": "125",
    "familyPrice": "310",
    "ingredients": [
      {
        "id": "bacon"
      },
      {
        "id": "lök"
      },
      {
        "id": "ägg"
      }
    ]
  },
  {
    "index": 17,
    "value": "ROYAL",
    "category": "KLASS 3",
    "price": "125",
    "familyPrice": "310",
    "ingredients": [
      {
        "id": "skinka"
      },
      {
        "id": "salami"
      },
      {
        "id": "bacon"
      },
      {
        "id": "lök"
      }
    ]
  },
  {
    "index": 18,
    "value": "QUATTRO STAGIONI",
    "category": "KLASS 3",
    "price": "125",
    "familyPrice": "310",
    "ingredients": [
      {
        "id": "skinka"
      },
      {
        "id": "champinjoner"
      },
      {
        "id": "musslor"
      },
      {
        "id": "räkor"
      },
      {
        "id": "kronärtskocka"
      }
    ]
  },
  {
    "index": 19,
    "value": "FRUTTI DI MARE",
    "category": "KLASS 3",
    "price": "125",
    "familyPrice": "310",
    "ingredients": [
      {
        "id": "musslor"
      },
      {
        "id": "räkor"
      },
      {
        "id": "crabsticks"
      },
      {
        "id": "tonfisk"
      }
    ]
  },
  {
    "index": 20,
    "value": "VEGETARIANA",
    "category": "KLASS 3",
    "price": "125",
    "familyPrice": "310",
    "ingredients": [
      {
        "id": "champinjoner"
      },
      {
        "id": "lök"
      },
      {
        "id": "färsk paprika"
      },
      {
        "id": "oliver"
      },
      {
        "id": "sparris"
      },
      {
        "id": "kronärtskocka"
      }
    ]
  }
]

export const price4 = [
  {
    "index": 21,
    "value": "MEXICANA",
    "category": "KLASS 4",
    "price": "130",
    "familyPrice": "320",
    "ingredients": [
      {
        "id": "köttfärs"
      },
      {
        "id": "lök"
      },
      {
        "id": "tacosås"
      },
      {
        "id": "jalapeno"
      }
    ]
  },
  {
    "index": 22,
    "value": "TACOPIZZA",
    "category": "KLASS 4",
    "price": "130",
    "familyPrice": "320",
    "ingredients": [
      {
        "id": "köttfärs"
      },
      {
        "id": "lök"
      },
      {
        "id": "tacosås"
      },
      {
        "id": "jalapeno"
      },
      {
        "id": "tacochips"
      }
    ]
  },
  {
    "index": 23,
    "value": "FANTASTICO",
    "category": "KLASS 4",
    "price": "130",
    "familyPrice": "320",
    "ingredients": [
      {
        "id": "köttfärs"
      },
      {
        "id": "lök"
      },
      {
        "id": "bacon"
      },
      {
        "id": "gorgonzola"
      },
      {
        "id": "salami"
      }
    ]
  },
  {
    "index": 24,
    "value": "ITALIA",
    "category": "KLASS 4",
    "price": "130",
    "familyPrice": "320",
    "ingredients": [
      {
        "id": "köttfärs"
      },
      {
        "id": "lök"
      },
      {
        "id": "skinka"
      },
      {
        "id": "salami"
      },
      {
        "id": "jalapeno"
      }
    ]
  },
  {
    "index": 25,
    "value": "BLANCO",
    "category": "KLASS 4",
    "price": "130",
    "familyPrice": "320",
    "ingredients": [
      {
        "id": "köttfärs"
      },
      {
        "id": "lök"
      },
      {
        "id": "champinjoner"
      },
      {
        "id": "färsk vitlök"
      }
    ]
  },
  {
    "index": 26,
    "value": "HYLLINGE",
    "category": "KLASS 4",
    "price": "130",
    "familyPrice": "320",
    "ingredients": [
      {
        "id": "köttfärs"
      },
      {
        "id": "lök"
      },
      {
        "id": "bacon"
      }
    ]
  },
  {
    "index": 27,
    "value": "BOLOGNESE",
    "category": "KLASS 4",
    "price": "130",
    "familyPrice": "320",
    "ingredients": [
      {
        "id": "hemlagad köttfärs"
      },
      {
        "id": "lök"
      }
    ]
  }
]

export const price5 = [
   {
    "index": 28,
    "value": "KYCKLINGPIZZA",
    "category": "KLASS 5",
    "price": "140",
    "familyPrice": "340",
    "ingredients": [
      {
        "id": "kyckling"
      },
      {
        "id": "banan"
      },
      {
        "id": "ananas"
      },
      {
        "id": "jordnötter"
      },
      {
        "id": "curry"
      },
      {
        "id": "bearnaise sås"
      }
    ]
  },
  {
    "index": 29,
    "value": "MELISSA SPECIAL",
    "category": "KLASS 5",
    "price": "140",
    "familyPrice": "340",
    "ingredients": [
      {
        "id": "kyckling"
      },
      {
        "id": "champinjoner"
      },
      {
        "id": "lök"
      },
      {
        "id": "paprika"
      },
      {
        "id": "valfri sås"
      }
    ]
  },
  {
    "index": 30,
    "value": "RUCCOLA",
    "category": "KLASS 5",
    "price": "140",
    "familyPrice": "340",
    "ingredients": [
      {
        "id": "kyckling"
      },
      {
        "id": "champinjoner"
      },
      {
        "id": "tomater"
      },
      {
        "id": "oliver"
      },
      {
        "id": "ruccola"
      },
      {
        "id": "valfri sås"
      }
    ]
  }
]

export const kebabPizzor = [
  {
    "index": 31,
    "value": "KEBABPIZZA",
    "category": "KEBABPIZZOR",
    "price": "145",
    "familyPrice": "360",
    "certainPrice": "135:- / 330:-",
    "info": "Valfri sås: vitlök, mild, stark",
    "ingredients": [
      {
        "id": "kebabkött"
      },
      {
        "id": "tomat"
      },
      {
        "id": "feferoni"
      }
    ],
    "sauces": [
      "vitlök",
      "mild",
      "stark"
    ]
  },
  {
    "index": 32,
    "value": "KEBAB SPECIAL",
    "category": "KEBABPIZZOR",
    "price": "145",
    "familyPrice": "360",
    "ingredients": [
      {
        "id": "kebabkött"
      },
      {
        "id": "isbergssallad"
      },
      {
        "id": "gurka"
      },
      {
        "id": "tomat"
      },
      {
        "id": "lök"
      },
      {
        "id": "feferoni"
      }
    ],
    "sauces": [
      "vitlök",
      "mild",
      "stark"
    ]
  },
  {
    "index": 33,
    "value": "GREKISK KEBABPIZZA",
    "category": "KEBABPIZZOR",
    "price": "145",
    "familyPrice": "360",
    "ingredients": [
      {
        "id": "kebabkött"
      },
      {
        "id": "tomat"
      },
      {
        "id": "gorgonzola"
      }
    ],
    "sauces": [
      "vitlök",
      "mild",
      "stark"
    ]
  },
  {
    "index": 34,
    "value": "DOMINO",
    "category": "KEBABPIZZOR",
    "price": "145",
    "familyPrice": "360",
    "ingredients": [
      {
        "id": "kebabkött"
      },
      {
        "id": "skinka"
      },
      {
        "id": "bacon"
      }
    ],
    "sauces": [
      "vitlök",
      "mild",
      "stark"
    ]
  },
  {
    "index": 35,
    "value": "BIBLOS",
    "category": "KEBABPIZZOR",
    "price": "145",
    "familyPrice": "360",
    "ingredients": [
      {
        "id": "kebabkött"
      },
      {
        "id": "skinka"
      },
      {
        "id": "lök"
      }
    ],
    "sauces": [
      "vitlök",
      "mild",
      "stark"
    ]
  },
  {
    "index": 36,
    "value": "AZTEKA",
    "category": "KEBABPIZZOR",
    "price": "145",
    "familyPrice": "360",
    "ingredients": [
      {
        "id": "kebabkött"
      },
      {
        "id": "champinjoner"
      },
      {
        "id": "tomat"
      },
      {
        "id": "pommes"
      }
    ],
    "sauces": [
      "vitlök",
      "mild",
      "stark"
    ]
  }
]

export const fläskfile = [
  {
    "index": 37,
    "value": "MADONNA",
    "category": "FLÄSKFILÉPIZZOR",
    "price": "150",
    "familyPrice": "370",
    "ingredients": [
      {
        "id": "fläskfilé"
      },
      {
        "id": "champinjoner"
      },
      {
        "id": "paprika"
      },
      {
        "id": "lök"
      },
      {
        "id": "bearnaisesås"
      }
    ]
  },
  {
    "index": 38,
    "value": "LAUNE",
    "category": "FLÄSKFILÉPIZZOR",
    "price": "150",
    "familyPrice": "370",
    "ingredients": [
      {
        "id": "fläskfilé"
      },
      {
        "id": "banan"
      },
      {
        "id": "jordnötter"
      },
      {
        "id": "curry"
      },
      {
        "id": "bearnaisesås"
      }
    ]
  },
  {
    "index": 39,
    "value": "HUSETS SPECIAL",
    "category": "FLÄSKFILÉPIZZOR",
    "price": "150",
    "familyPrice": "370",
    "ingredients": [
      {
        "id": "fläskfilé"
      },
      {
        "id": "skinka"
      },
      {
        "id": "bacon"
      },
      {
        "id": "lök"
      },
      {
        "id": "färsk vitlök"
      },
      {
        "id": "bearnaisesås"
      }
    ]
  }
]


export const oxfile = [
  {
    "index": 40,
    "value": "BAGAREN SPECIAL",
    "category": "OXFILÉPIZZOR",
    "price": "160",
    "familyPrice": "390",
    "ingredients": [
      {
        "id": "oxfilé"
      },
      {
        "id": "champinjoner"
      },
      {
        "id": "lök"
      },
      {
        "id": "paprika"
      },
      {
        "id": "jalapeno"
      },
      {
        "id": "färsk vitlök"
      },
      {
        "id": "bearnaisesås"
      }
    ]
  },
  {
    "index": 41,
    "value": "ACAPULCO",
    "category": "OXFILÉPIZZOR",
    "price": "160",
    "familyPrice": "390",
    "ingredients": [
      {
        "id": "oxfilé"
      },
      {
        "id": "champinjoner"
      },
      {
        "id": "lök"
      },
      {
        "id": "jalapeno"
      },
      {
        "id": "tacosås"
      },
      {
        "id": "färsk paprika"
      },
      {
        "id": "bearnaisesås"
      }
    ]
  },
  {
    "index": 42,
    "value": "HIF SPECIAL",
    "category": "OXFILÉPIZZOR",
    "price": "160",
    "familyPrice": "390",
    "ingredients": [
      {
        "id": "oxfilé"
      },
      {
        "id": "champinjoner"
      },
      {
        "id": "lök"
      },
      {
        "id": "bearnaisesås"
      }
    ]
  },
  {
    "index": 43,
    "value": "SWEDEN",
    "category": "OXFILÉPIZZOR",
    "price": "160",
    "familyPrice": "390",
    "ingredients": [
      {
        "id": "oxfilé"
      },
      {
        "id": "fläskfilé"
      },
      {
        "id": "champinjoner"
      },
      {
        "id": "sparris"
      },
      {
        "id": "räkor"
      },
      {
        "id": "bearnaisesås"
      }
    ]
  },
  {
    "index": 45,
    "value": "BLACK & WHITE",
    "category": "OXFILÉPIZZOR",
    "price": "160",
    "familyPrice": "390",
    "ingredients": [
      {
        "id": "oxfilé"
      },
      {
        "id": "fläskfilé"
      },
      {
        "id": "paprika"
      },
      {
        "id": "lök"
      },
      {
        "id": "bearnaisesås"
      }
    ]
  }
]

export const mozzarellaPizzor =  [
  {
    "index": 46,
    "value": "PARMA SPECIAL",
    "category": "MOZZARELLAPIZZOR",
    "price": "160",
    "familyPrice": "390",
    "ingredients": [
      {
        "id": "mozzarella"
      },
      {
        "id": "körsbärstomater"
      },
      {
        "id": "oliver"
      },
      {
        "id": "färsk vitlök"
      },
      {
        "id": "lufttorkad skinka"
      },
      {
        "id": "ruccola"
      }
    ]
  },
  {
    "index": 47,
    "value": "TOSCANA",
    "category": "MOZZARELLAPIZZOR",
    "price": "160",
    "familyPrice": "390",
    "ingredients": [
      {
        "id": "mozzarella"
      },
      {
        "id": "soltorkade tomater"
      },
      {
        "id": "grillad paprika"
      },
      {
        "id": "pesto"
      },
      {
        "id": "lufttorkad skinka"
      },
      {
        "id": "ruccola"
      }
    ]
  },
  {
    "index": 48,
    "value": "HYLLINGE SPECIAL",
    "category": "MOZZARELLAPIZZOR",
    "price": "160",
    "familyPrice": "390",
    "ingredients": [
      {
        "id": "mozzarella"
      },
      {
        "id": "champinjoner"
      },
      {
        "id": "salami"
      },
      {
        "id": "bacon"
      },
      {
        "id": "soltorkade tomater"
      },
      {
        "id": "gorgonzola"
      },
      {
        "id": "färsk vitlök"
      }
    ]
  },
  {
    "index": 49,
    "value": "JÄGARE",
    "category": "MOZZARELLAPIZZOR",
    "price": "160",
    "familyPrice": "390",
    "ingredients": [
      {
        "id": "mozzarella"
      },
      {
        "id": "skinka"
      },
      {
        "id": "oxfilé"
      },
      {
        "id": "köttfärs"
      },
      {
        "id": "salami"
      },
      {
        "id": "färsk vitlök"
      }
    ]
  },
  {
    "index": 50,
    "value": "ZLATAN SPECIAL",
    "category": "MOZZARELLAPIZZOR",
    "price": "160",
    "familyPrice": "390",
    "ingredients": [
      {
        "id": "mozzarella"
      },
      {
        "id": "oxfilé"
      },
      {
        "id": "kyckling"
      },
      {
        "id": "paprika"
      },
      {
        "id": "körsbärstomater"
      },
      {
        "id": "vitost"
      },
      {
        "id": "färsk vitlök"
      }
    ]
  },
    {
      "index": 51,
      "value": "FYRA OSTAR",
      "category": "MOZZARELLAPIZZOR",
      "price": "160",
      "certainPrice": "145:-",
      "familyPrice": null,
      "ingredients": [
        {
          "id": "mozzarella"
        },
        {
          "id": "gorgonzola"
        },
        {
          "id": "vitost"
        },
        {
          "id": "ruccola"
        }
      ]
    }
  ]
  
export const amerikanska = [
  {
    "index": 52,
    "value": "SAN FRANSISCO",
    "category": "AMERIKANSKA PIZZOR",
    "price": "160",
    "familyPrice": null,
    "ingredients": [
      {
        "id": "skinka"
      },
      {
        "id": "champinjoner"
      },
      {
        "id": "tomater"
      },
      {
        "id": "paprika"
      },
      {
        "id": "lök"
      }
    ]
  },
  {
    "index": 53,
    "value": "ARIZONA",
    "category": "AMERIKANSKA PIZZOR",
    "price": "160",
    "familyPrice": null,
    "ingredients": [
      {
        "id": "skinka"
      },
      {
        "id": "salami"
      },
      {
        "id": "bacon"
      },
      {
        "id": "tomater"
      },
      {
        "id": "lök"
      },
      {
        "id": "gorgonzola"
      }
    ]
  },
  {
    "index": 54,
    "value": "LAS VEGAS",
    "category": "AMERIKANSKA PIZZOR",
    "price": "160",
    "familyPrice": null,
    "ingredients": [
      {
        "id": "skinka"
      },
      {
        "id": "champinjoner"
      },
      {
        "id": "salami"
      },
      {
        "id": "tomater"
      },
      {
        "id": "ananas"
      },
      {
        "id": "köttfärs"
      }
    ]
  },
  {
    "index": 55,
    "value": "DALLAS",
    "category": "AMERIKANSKA PIZZOR",
    "price": "160",
    "familyPrice": null,
    "ingredients": [
      {
        "id": "skinka"
      },
      {
        "id": "champinjoner"
      },
      {
        "id": "tomater"
      },
      {
        "id": "oxfilé"
      },
      {
        "id": "bearnaisesås"
      }
    ]
  },
  {
    "index": 56,
    "value": "NEW YORK",
    "category": "AMERIKANSKA PIZZOR",
    "price": "160",
    "familyPrice": null,
    "ingredients": [
      {
        "id": "oxfilé"
      },
      {
        "id": "lök"
      },
      {
        "id": "paprika"
      },
      {
        "id": "tomater"
      },
      {
        "id": "jalapeno"
      },
      {
        "id": "tacosås"
      }
    ]
  }
]

export const halvinbakade = [
  {
    "id": "57",
    "index": 57,
    "value": "VIKINGBÅT",
    "category": "HALVINBAKADE PIZZOR",
    "info": "(Valfri sås ingår)",
    "price": "145",
    "familyPrice": null,
    "ingredients": [
      {
        "id": "kebabkött",
        "value": "kebabkött"
      },
      {
        "id": "lök",
        "value": "lök"
      },
      {
        "id": "champinjoner",
        "value": "champinjoner"
      },
      {
        "id": "tomater",
        "value": "tomater"
      }
    ]
  },
  {
    "id": "58",
    "index": 58,
    "value": "CIAO CIAO",
    "category": "HALVINBAKADE PIZZOR",
    "price": "145",
    "familyPrice": null,
    "ingredients": [
      {
        "id": "oxfilé",
        "value": "oxfilé"
      },
      {
        "id": "lök",
        "value": "lök"
      },
      {
        "id": "champinjoner",
        "value": "champinjoner"
      },
      {
        "id": "tomater",
        "value": "tomater"
      }
    ]
  },
  {
    "id": "59",
    "index": 59,
    "value": "FAVORIT",
    "category": "HALVINBAKADE PIZZOR",
    "price": "145",
    "familyPrice": null,
    "ingredients": [
      {
        "id": "fläskfilé",
        "value": "fläskfilé"
      },
      {
        "id": "lök",
        "value": "lök"
      },
      {
        "id": "champinjoner",
        "value": "champinjoner"
      },
      {
        "id": "tomater",
        "value": "tomater"
      }
    ]
  },
  {
    "id": "60",
    "index": 60,
    "value": "KUNG SPECIAL",
    "category": "HALVINBAKADE PIZZOR",
    "price": "145",
    "familyPrice": null,
    "ingredients": [
      {
        "id": "kyckling",
        "value": "kyckling"
      },
      {
        "id": "köttfärs",
        "value": "köttfärs"
      },
      {
        "id": "lök",
        "value": "lök"
      },
      {
        "id": "champinjoner",
        "value": "champinjoner"
      },
      {
        "id": "curry",
        "value": "curry"
      }
    ]
  }
]

export const inbakade = [  
{   
   "id": "61",    
   "index": 61,    
   "value": "CALZONE",    
   "category": "INBAKADE PIZZOR",
   "price": "120",    
   "certainPrice": "120:-",
   "ingredients": [      
    {        
      "id": "skinka"      
    }    
  ]
},
{
  "id": "62",
  "index": 62,
  "value": "CALZONE CAPRI",
  "category": "INBAKADE PIZZOR",
  "price": "125",
  "certainPrice": "125:-",
  "ingredients": [
    {
      "id": "skinka"
    },
    {
      "id": "champinjoner"
    }
  ]
},
{
  "id": "63",
  "index": 63,
  "value": "CALZONE HAWAII",
  "category": "INBAKADE PIZZOR",
  "price": "125",
  "certainPrice": "125:-",
  "ingredients": [
    {
      "id": "skinka"
    },
    {
      "id": "ananas"
    }
  ]
},
{
  "id": "64",
  "index": 64,
  "value": "CALZONE SPECIAL",
  "category": "INBAKADE PIZZOR",
  "price": "130",
  "certainPrice": "130:-",
  "ingredients": [
    {
      "id": "skinka"
    },
    {
      "id": "champinjoner"
    },
    {
      "id": "räkor"
    }
  ]
},
{
  "id": "65",
  "index": 65,
  "value": "PRINSESSA",
  "category": "INBAKADE PIZZOR",
  "price": "140",
  "certainPrice": "140:-",
  "ingredients": [
    {
      "id": "skinka"
    },
    {
      "id": "kebab"
    },
    {
      "id": "lök"
    },
    {
      "id": "färsk vitlök"
    }
  ]
}
]

export const allProductsData = price1.concat(price2, price3, price4, price5, kebabPizzor, fläskfile, oxfile, mozzarellaPizzor, amerikanska, halvinbakade, inbakade)

export const allKebabMeny = [
  {
    "index": 1,
    "value": "Kebabtallrik",
    "category": "Kebabmeny",
    "info": "Isbergssallad, tomat, gurka, lök feferoni och sås ingår",
    "certainPrice": "120:-",
    "ingredients": [
      {
        "id": "kebabkött"
      },
      {
        "id": "pommes"
      }
    ]
  },
  {
    "index": 2,
    "value": "Kebabrulle",
    "category": "Kebabmeny",
    "certainPrice": "120:-",
    "ingredients": [
      {
        "id": "färskt hembakat bröd"
      },
      {
        "id": "kebabkött"
      }
    ]
  },
  {
    "index": 3,
    "value": "Kebab med bröd",
    "category": "Kebabmeny",
    "certainPrice": "120:-",
    "ingredients": [
      {
        "id": "färskt hembakat bröd"
      },
      {
        "id": "pommes"
      }
    ]
  },
  {
    "index": 4,
    "value": "Kycklingtallrik",
    "category": "Kebabmeny",
    "certainPrice": "130:-",
    "ingredients": [
      {
        "id": "kycklingkött"
      },
      {
        "id": "pommes"
      }
    ]
  },
  {
    "index": 5,
    "value": "Kycklingrullerulle",
    "category": "Kebabmeny",
    "certainPrice": "130:-",
    "ingredients": [
      {
        "id": "färskt hembakat bröd"
      },
      {
        "id": "kycklingkött"
      }
    ]
  },
  {
    "index": 6,
    "value": "Kyckling med bröd",
    "category": "Kebabmeny",
    "certainPrice": "130:-",
    "ingredients": [
      {
        "id": "färskt hembakat bröd"
      },
      {
        "id": "kycklingkött"
      }
    ]
  },
  {
    "index": 7,
    "value": "Kycklingkebabrulle",
    "category": "Kebabmeny",
    "certainPrice": "140:-",
    "ingredients": [
      {
        "id": "färskt hembakat bröd"
      },
      {
        "id": "kycklingkebab"
      }
    ]
  },
  {
    "index": 8,
    "value": "Falafeltallrik",
    "category": "Kebabmeny",
    "certainPrice": "110:-",
    "ingredients": [
      {
        "id": "falafel"
      },
      {
        "id": "pommes"
      }
    ]
  },
  {
    "index": 9,
    "value": "Falafelrulle",
    "category": "Kebabmeny",
    "certainPrice": "110:-",
    "ingredients": [
      {
        "id": "färskt hembakat bröd"
      },
      {
        "id": "falafel"
      }
    ]
  },
  {
    "index": 10,
    "value": "Kebabrulle special",
    "category": "Kebabmeny",
    "certainPrice": "140:-",
    "ingredients": [
      {
        "id": "färskt hembakat bröd"
      },
      {
        "id": "kebabkött"
      },
      {
        "id": "pommes"
      },
      {
        "id": "vitost"
      }
    ]
  }
]

export const allSallad = [
  {
    "index": 1,
    "value": "Räksallad",
    "category": "Sallader",
    "info": "Färskt hembakat bröd, sallad, tomat, gurka och valfri sås ingår",
    "price": "130",
    "ingredients": [
      {
        "id": "räkor"
      },
      {
        "id": "ost"
      },
      {
        "id": "citron"
      },
      {
        "id": "oliver"
      }
    ]
  },
  {
    "index": 2,
    "value": "Skinksallad",
    "category": "Sallader",
    "price": "130",
    "ingredients": [
      {
        "id": "skinka"
      },
      {
        "id": "ost"
      },
      {
        "id": "citron"
      },
      {
        "id": "oliver"
      }
    ]
  },
  {
    "index": 3,
    "value": "Kebabsallad",
    "category": "Sallader",
    "price": "130",
    "ingredients": [
      {
        "id": "kebab"
      },
      {
        "id": "lök"
      },
      {
        "id": "feferoni"
      }
    ]
  },
  {
    "index": 4,
    "value": "Kycklingsallad",
    "category": "Sallader",
    "price": "130",
    "ingredients": [
      {
        "id": "kyckling"
      },
      {
        "id": "ananas"
      },
      {
        "id": "paprika"
      },
      {
        "id": "oliver"
      }
    ]
  },
  {
    "index": 5,
    "value": "Grekisk sallad",
    "category": "Sallader",
    "price": "130",
    "ingredients": [
      {
        "id": "vitost"
      },
      {
        "id": "paprika"
      },
      {
        "id": "lök"
      },
      {
        "id": "oliver"
      },
      {
        "id": "körsbärstomater"
      },
      {
        "id": "feferoni"
      }
    ]
  },
  {
    "index": 6,
    "value": "Mixsallad",
    "category": "Sallader",
    "price": "130",
    "ingredients": [
      {
        "id": "ost"
      },
      {
        "id": "kyckling"
      },
      {
        "id": "räkor"
      },
      {
        "id": "tonfisk"
      },
      {
        "id": "ananas"
      }
    ]
  },
  {
    "index": 7,
    "value": "Tonfisksallad",
    "category": "Sallader",
    "price": "130",
    "ingredients": [
      {
        "id": "tonfisk"
      },
      {
        "id": "lök"
      },
      {
        "id": "citron"
      }
    ]
  },
  {
    "index": 8,
    "value": "Vegetarisk sallad",
    "category": "Sallader",
    "price": "130",
    "ingredients": [
      {
        "id": "lök"
      },
      {
        "id": "oliver"
      },
      {
        "id": "feferoni"
      },
      {
        "id": "ananas"
      }
    ]
  },
]

export const hamburger = [
  {
    "index": 6,
    "value": "Hamburgare 90g",
    "category": "HAMBURGERMENY",
    "info": "Till alla hamburgare ingår sallad, dressing, lök & tomat",
    "certainPrice": "Bröd 80:-, Pommes 100:-",
    "ingredients": []
  },
  {
    "index": 7,
    "value": "Hamburgare 150g",
    "category": "HAMBURGERMENY",
    "certainPrice": "Bröd 90:-, Pommes 110:-",
    "ingredients": []
  },
  {
    "index": 8,
    "value": "Hamburgare Special 150g",
    "category": "HAMBURGERMENY",
    "certainPrice": "Bröd 110:-, Pommes 120:-",
    "ingredients": [
      {
        "id": "bacon"
      },
      {
        "id": "ost"
      }
    ]
  },
  {
    "index": 9,
    "value": "Chicken nuggets, 8st",
    "category": "HAMBURGERMENY",
    "certainPrice": "100:-",
    "ingredients": [
      {
        "id": "pommes"
      }
    ]
  },
  {
    "index": 10,
    "value": "Pommes",
    "category": "HAMBURGERMENY",
    "certainPrice": "Liten 40:-, Stor 60:-",
    "ingredients": [
      {
        "id": "pommes"
      }
    ]
  },
  {
    "index": 11,
    "value": "Pizzabröd med färsk vitlök",
    "category": "HAMBURGERMENY",
    "certainPrice": "70:-",
    "ingredients": []
  },
]

export const aLaCarte = [
  {
    "index": 1,
    "value": "FLÄSKFILÉ A LA HYLLINGE",
    "category": "A LA CARTÉ",
    "info": "Serveras med sallad, tomat, gurka och stekt potatis eller pommes",
    "certainPrice": "160:-",
    "ingredients": [
      {
        "id": "fläskfilé"
      },
      {
        "id": "sparris"
      },
      {
        "id": "bacon"
      },
      {
        "id": "sallad"
      },
      {
        "id": "bearnaisesås"
      }
    ]
  },
  {
    "index": 2,
    "value": "KYCKLINGFILÉ",
    "category": "A LA CARTÉ",
    "certainPrice": "160:-",
    "ingredients": [
      {
        "id": "sallad"
      },
      {
        "id": "bearnaisesås"
      }
    ]
  },
  {
    "index": 3,
    "value": "SCHNITZEL",
    "category": "A LA CARTÉ",
    "certainPrice": "130:-",
    "ingredients": [
      {
        "id": "bearnaisesås"
      },
      {
        "id": "citron"
      }
    ]
  },
  {
    "index": 4,
    "value": "RÖDSPÄTTA",
    "category": "A LA CARTÉ",
    "certainPrice": "130:-",
    "ingredients": [
      {
        "id": "remouladesås"
      },
      {
        "id": "citron"
      }
    ]
  } // Vi har följande såser: mild sås, stark tomatsås, vitlöksås, bearnaisesås.
]

export const langos = [
  {
    "index": 5,
    "value": "Välj mellan",
    "category": "LANGOS",
    "info": "Isbergssallad, tomat, gurka, lök, ost och valfri sås ingår",
    "price": "125",
    "ingredients": [
      {
        "id": "Kyckling"
      },
      {
        "id": "Kebab"
      },
      {
        "id": "Räkor"
      },
      {
        "id": "Skinka"
      },
      {
        "id": "Nutella (banan, ananas)"
      }
    ]
  }
]

export const allALaCarte = aLaCarte.concat(langos, hamburger)
